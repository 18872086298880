import {
  INITIATE_PAYMENT,
  PAYMENT_SUCCESS,
  SET_ERROR, SET_MESSAGE
} from "../actions";

export const INIT_STATE = {
  error: '',
  message: '',
  invoiceId: '',
  amount: '',
  note: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload.error };
    case PAYMENT_SUCCESS:
      return { ...state };
    case INITIATE_PAYMENT:
      return { ...state, invoiceId: action.payload.invoiceId, invoiceNumber: action.payload.invoiceNumber, note: action.payload.note, amount: action.payload.amount };
    case SET_MESSAGE:
      return { ...state, message: action.payload.message };
    default:
      return { ...state };
  }
};
