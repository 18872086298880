
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { baseURL } from '../../config/api';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SET_USER_ID,
    SET_USER,
    FETCH_INVOICES,
    PRINT_INVOICE,
    FILTER_STATE,
    FILTER_SEARCH,
    NEXT_PAGE,
    PREVIOUS_PAGE
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    setUserId,
    setUser,
    setInvoices,
    setPagination
} from './actions';


import { INIT_STATE as authUser } from './reducer';
import { toast } from 'react-toastify';

export function* watchLoginUserSuccess() {
    yield takeEvery(LOGIN_USER_SUCCESS, successfulLogin);
}


function* successfulLogin({ payload }) {
    const { response, password, navigator } = payload;
    const { partner_id, user_id } = response;
    localStorage.setItem('user_id', user_id);
    localStorage.setItem('partner_id', partner_id);
    localStorage.setItem('password', password);
    yield put(setUserId(user_id, partner_id, password));
}


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchSetUserId() {
    yield takeEvery(SET_USER_ID, fetchCustomer);
}

export function* watchFetchInvoices() {
    yield takeEvery(FETCH_INVOICES, fetchInvoices);
}

export function* watchPrintInvoice() {
    yield takeEvery(PRINT_INVOICE, printInvoice);
}

export function* watchFilterState() {
    yield takeEvery(FILTER_STATE, filterState);
}

export function* watchFilterSearch() {
    yield takeEvery(FILTER_SEARCH, filterSearch);
}

export function* watchNextPage() {
    yield takeEvery(NEXT_PAGE, nextPage);
}

export function* watchPreviousPage() {
    yield takeEvery(PREVIOUS_PAGE, previousPage);
}

const loginWithEmailPasswordAsync = async (credentials) => {
    try {
        const request = await fetch(`${baseURL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}

const fetchCustomerAsync = async (user_id, partner_id, password) => {
    try {
        const request = await fetch(`${baseURL}/customer/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id,
                partner_id,
                password
            })
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}

const fetchInvoicesAsync = async (user_id, partner_id, password, state, search, pagination) => {
    try {
        const request = await fetch(`${baseURL}/invoices/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id,
                partner_id,
                state,
                search,
                pagination,
                password
            })
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}

const printInvoiceAsync = async (user_id, invoice_id, password) => {
    try {
        const request = await fetch(`${baseURL}/invoices/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id,
                invoice_id,
                password
            })
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}


function* loginWithEmailPassword({ payload }) {
    const { credentials, navigator } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, credentials);
        const { ok, response } = loginUser;
        const { message } = response;
        if (ok) {
            yield put(loginUserSuccess(response, credentials.password, navigator));
        } else {
            yield put(loginUserError(message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* fetchCustomer({ payload }) {
    try {

        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password } = authUser;
        const customer = yield call(fetchCustomerAsync, user_id, partner_id, password);
        const { ok, response } = customer;
        const { message } = response;
        if (ok) {
            localStorage.setItem('user', JSON.stringify(response));
            yield put(setUser(response));
        } else {
            yield put(loginUserError(message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* fetchInvoices({ payload }) {
    try {

        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password } = authUser;
        const invoices = yield call(fetchInvoicesAsync, user_id, partner_id, password);
        const { ok, response } = invoices;
        const { message } = response;
        if (ok) {
            localStorage.setItem('invoices', JSON.stringify(response));
            yield put(setInvoices(response));
        } else {
            yield put(loginUserError(message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}


const urltoFile = async (url, filename, mimeType) => {
    const file = await fetch(url);
    const buffer = await file.arrayBuffer();
    return new File([ buffer ], filename, { type: mimeType });
}


function* printInvoice({ payload }) {
    try {
        const { invoiceId, fileName } = payload;
        const authUser = yield select( (state) => state.authUser );
        const { user_id, password } = authUser;
        const invoiceFile = yield call(printInvoiceAsync, user_id, invoiceId, password);
        const { ok, response } = invoiceFile;
        const { message } = response;
        if (ok) {
            const fileLink = yield call(urltoFile, `data:${response.mimetype};base64,${response.result}`, fileName, response.mimetype);
            saveAs(fileLink);
        } else {
            yield put(loginUserError(message));
        }        
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* filterState({ payload }) {
    try {
        const { state } = payload;
        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password, filterSearch } = authUser;
        const invoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, state, filterSearch);
        const { ok, response } = invoices;
        const { message } = response;
        if (ok) {
            localStorage.setItem('invoices', JSON.stringify(response));
            yield put(setInvoices(response));
        } else {
            yield put(loginUserError(message));
        }        
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* filterSearch({ payload }) {
    try {
        const { search } = payload;
        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password, filterState, pagination } = authUser;
        const invoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, filterState, search, pagination);
        const { ok, response } = invoices;
        const { message } = response;
        if (ok) {
            localStorage.setItem('invoices', JSON.stringify(response));
            yield put(setInvoices(response));
        } else {
            yield put(loginUserError(message));
        }        
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* nextPage({ payload }) {
    try {
        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password, filterState, filterSearch, pagination } = authUser;
        const invoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, filterState, filterSearch, { start: pagination.start + pagination.limit, limit: pagination.limit });
        const { ok, response } = invoices;
        const { message } = response;
        if (ok) {
            if(response.length > 0) {
                const secondNextInvoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, filterState, filterSearch, { start: pagination.start + (pagination.limit * 2), limit: pagination.limit });
                const { response: secondNextResponse } = secondNextInvoices;
                yield put(setPagination({ start: pagination.start + pagination.limit, limit: pagination.limit, canGoBack: true, canGoForward: secondNextResponse.length > 0 }));
            }
            localStorage.setItem('invoices', JSON.stringify(response));
            yield put(setInvoices(response));
        } else {
            yield put(loginUserError(message));
        }        
    } catch (error) {
        yield put(loginUserError(error));
    }
}

function* previousPage({ payload }) {
    try {
        const authUser = yield select( (state) => state.authUser );
        const { user_id, partner_id, password, filterState, filterSearch, pagination } = authUser;
        const invoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, filterState, filterSearch, { start: pagination.start - pagination.limit, limit: pagination.limit });
        const { ok, response } = invoices;
        const { message } = response;
        if (ok) {
            if(response.length > 0) {
                const secondLastInvoices = yield call(fetchInvoicesAsync, user_id, partner_id, password, filterState, filterSearch, { start: pagination.start - (pagination.limit * 2), limit: pagination.limit });
                const { response: secondLastResponse } = secondLastInvoices;
                yield put(setPagination({ start: pagination.start - pagination.limit, limit: pagination.limit, canGoBack: secondLastResponse.length > 0, canGoForward: true }));
            }
            localStorage.setItem('invoices', JSON.stringify(response));
            yield put(setInvoices(response));
        } else {
            yield put(loginUserError(message));
        }        
    } catch (error) {
        yield put(loginUserError(error));
    }
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) => await null
    // await auth.createUserWithEmailAndPassword(email, password)
    //     .then(authUser => authUser)
    //     .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (navigator) => {
    setUser(null);
    setUserId(null);
    localStorage.clear();
    return await navigator('/');
}

function* logout({ payload }) {
    const { navigator } = payload;
    try {
        yield call(logoutAsync, navigator);
    } catch (error) {
        console.error(error);
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {    
    try {
        const request = await fetch(`${baseURL}/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        const { ok, response } = forgotPasswordStatus;
        const { message } = response;
        if (ok) {
            yield put(forgotPasswordSuccess(message));
            toast.success(`Per favore controlla la tua casella di posta.`);
        } else {
            yield put(forgotPasswordError(message));
        }

    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    const { baseURL } = authUser;
    let status = 400;
    const request = await fetch(`${baseURL}/reset-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            resetPasswordCode,
            newPassword
        })
    })
    .then( response => {
        status = response.status;
        return response.json();
    })
    .then( response => response )
    .catch( error => error );


    return {
        request,
        status
    };

    // return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
    //     .then(user => user)
    //     .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (resetPasswordStatus.status === 200) {
            yield put(resetPasswordSuccess(resetPasswordStatus.request.message));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.request.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLoginUserSuccess),
        fork(watchSetUserId),
        fork(watchFetchInvoices),
        fork(watchPrintInvoice),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchFilterState),
        fork(watchFilterSearch),
        fork(watchNextPage),
        fork(watchPreviousPage)
    ]);
}