import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import payment from './payment/reducer';


const reducers = combineReducers({
  authUser,
  payment
});

export default reducers;