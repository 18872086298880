import { useState } from "react";
import { connect } from "react-redux";
import { logoutUser, toggleSidebar } from '../redux/actions';
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

function Navbar({ logoutUser, toggleSidebar, sidebarOpened, user }) {
    
    const navigator = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDropdown = (e) => {
        e.preventDefault();
        setOpen(!open);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        return logoutUser(navigator);
    }

    return (
        <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
             
                <div className="flex items-center justify-between w-full md:order-2">

                    <button onClick={ () => toggleSidebar(!sidebarOpened) } type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-600 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>

                    <div className="relative text-gray-900 bg-white text-sm px-4 flex items-center">

                        <div className="cursor-pointer flex items-center" onClick={ (e) => handleDropdown(e) }>
                            <div>
                                <p className="mb-0 font-medium">{user.name}</p>
                                <p className="mb-0">{user.email}</p>
                            </div>
                            <div>
                                <svg className="w-4 h-4 ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                            </div>
                        </div>
                        

                        <div className={classNames({ "absolute top-full right-0 mt-3 mr-4 z-50 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600": true, "hidden": !open })}>
                            <ul className="py-1">
                                <li>
                                    <a href="#" onClick={ (e) => handleLogout(e) } className="block py-2 px-8 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                                </li>
                            </ul>
                        </div>

                        
                    </div>



                </div>
                
            </div>
        </nav>
    );
}


const mapStateToProps = ({ authUser }) => {
    const { user, sidebarOpened } = authUser;
    return { user, sidebarOpened };
};
const mapActionsToProps = {logoutUser, toggleSidebar};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Navbar);