import { select } from 'redux-saga/effects';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_USER_ID,
  SET_USER,
  SET_INVOICES,
  FETCH_INVOICES,
  PRINT_INVOICE,
  TOGGLE_SIDEBAR,
  FILTER_STATE,
  FILTER_SEARCH,
  NEXT_PAGE,
  SET_PAGINATION,
  PREVIOUS_PAGE
} from '../actions';

export const loginUser = (credentials, navigator) => ({
  type: LOGIN_USER,
  payload: { credentials, navigator }
});

export const nextPage = () => ({
  type: NEXT_PAGE,
  payload: { }
});

export const previousPage = () => ({
  type: PREVIOUS_PAGE,
  payload: { }
});

export const setPagination = (pagination) => ({
  type: SET_PAGINATION,
  payload: { pagination }
});

export const toggleSidebar = (state) => ({
  type: TOGGLE_SIDEBAR,
  payload: { state }
});

export const filterState = (state) => {
  return (dispatch, getState) => {
    const { authUser } = getState();
    const { filterState } = authUser;
    let finalState = filterState;
    if(!finalState.includes(state)) {
      finalState.push(state);
    } else {
      finalState = finalState.filter( x => x !== state );
    }
    dispatch({
      type: FILTER_STATE,
      payload: { state: finalState }
    });
  }
}

export const filterSearch = (search) => ({
  type: FILTER_SEARCH,
  payload: { search }
});

export const loginUserSuccess = (response, password, navigator) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { response, password, navigator }
});

export const setUserId = (userId, partnerId, password) => ({
  type: SET_USER_ID,
  payload: { userId, partnerId, password }
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user }
});

export const setInvoices = (invoices) => ({
  type: SET_INVOICES,
  payload: { invoices }
});

export const fetchInvoices = () => ({
  type: FETCH_INVOICES,
  payload: { }
});

export const printInvoice = (invoiceId, fileName) => ({
  type: PRINT_INVOICE,
  payload: { invoiceId, fileName }
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({resetPasswordCode,newPassword,history}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode,newPassword,history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (navigator) => ({
  type: LOGOUT_USER,
  payload: { navigator }
});