import {
  INITIATE_PAYMENT,
  PAYMENT_SUCCESS,
  SET_ERROR, SET_MESSAGE
} from '../actions';

export const setError = (error) => ({
  type: SET_ERROR,
  payload: { error }
});

export const initiatePayment = (invoiceId, invoiceNumber, note, amount) => ({
  type: INITIATE_PAYMENT,
  payload: { invoiceId, invoiceNumber, note, amount }
});

export const paymentSuccess = () => ({
  type: PAYMENT_SUCCESS,
  payload: { }
});

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: { message }
});