
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { baseURL } from '../../config/api';
import { INITIATE_PAYMENT, loginUserError, fetchInvoices, paymentSuccess, PAYMENT_SUCCESS, SET_ERROR } from '../actions';
import { setError } from './actions';

export function* watchInitiatePayment() {
    yield takeEvery(INITIATE_PAYMENT, initiatePayment);
}

export function* watchPaymentSuccess() {
    yield takeEvery(PAYMENT_SUCCESS, manualPaymentSuccess);
}

export function* watchSetError() {
    yield takeEvery(SET_ERROR, manualSetError);
}


function* manualPaymentSuccess({ payload }) {
    try {
        yield put(fetchInvoices());
        window.location.reload();
    } catch (error) {
        yield put(setError(error));
    }
}

function* manualSetError({ payload }) {
    const { error } = yield select( (state) => state.payment );
    console.error(error);
}

const initiatePaymentAsync = async (user_id, invoice_id, invoice_number, password, amount, note) => {
    try {
        const request = await fetch(`${baseURL}/invoices/pay`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id,
                invoice_id,
                invoice_number,
                amount,
                note,
                password
            })
        });
        const response = await request.json();
        if(!request.ok) {
            throw new Error(response.message);
        }
        return {
            ok: true,
            response
        };
    } catch(e) {
        return {
            ok: false,
            response: {
                message: e.message
            }
        };
    }
}


function* initiatePayment({ payload }) {
    try {
        const { authUser } = yield select( (state) => state );
        const { user_id, password } = authUser;
        const { invoiceId, invoiceNumber, amount, note } = payload;
        const paymentResponse = yield call(initiatePaymentAsync, user_id, invoiceId, invoiceNumber, password, amount, note);
        const { ok, response } = paymentResponse;
        const { message } = response;
        if (ok) {
            yield put(paymentSuccess());
        } else {
            yield put(setError(message));
        }
    } catch (error) {
        yield put(setError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchInitiatePayment),
        fork(watchPaymentSuccess),
        fork(watchSetError)
    ]);
}