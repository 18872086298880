/* PAYMENT */
export const SET_ERROR = "SET_ERROR";
export const SET_MESSAGE = "SET_MESSAGE";
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER = "SET_USER";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const FILTER_STATE = "FILTER_STATE";
export const FILTER_SEARCH = "FILTER_SEARCH";

export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const SET_PAGINATION = "SET_PAGINATION";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const SET_INVOICES = "SET_INVOICES";
export const PRINT_INVOICE = "PRINT_INVOICE";

export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export * from "./auth/actions";
export * from "./payment/actions";
