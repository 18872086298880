import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_USER_ID,
  SET_USER,
  SET_INVOICES,
  FETCH_INVOICES,
  PRINT_INVOICE,
  TOGGLE_SIDEBAR,
  FILTER_STATE,
  FILTER_SEARCH,
  NEXT_PAGE,
  SET_PAGINATION,
  PREVIOUS_PAGE,
} from "../actions";

const localStorageInvoices = localStorage.getItem("invoices");
const localStorageUser = localStorage.getItem("user");
const localStorageUserId = localStorage.getItem("user_id");
const localStoragePartnerId = localStorage.getItem("partner_id");
const localStoragePassword = localStorage.getItem("password");

export const INIT_STATE = {
  invoices: localStorageInvoices ? JSON.parse(localStorageInvoices) : localStorage.getItem("invoices"),
  user: localStorageUser ? JSON.parse(localStorageUser) : localStorage.getItem("user"),
  user_id: localStorageUserId ? JSON.parse(localStorageUserId) : localStorage.getItem("user_id"),
  partner_id: localStoragePartnerId ? JSON.parse(localStoragePartnerId) : localStorage.getItem("partner_id"),
  password: localStoragePassword ? localStorage.getItem("password") : null,
  forgotUserMail: "",
  forgotUserMessage: "",
  newPassword: "",
  resetPasswordCode: "",
  resetPasswordMessage: "",
  loading: false,
  sidebarOpened: false,
  filterState: [],
  filterSearch: '',
  pagination: {
    start: 0,
    limit: 10,
    canGoBack: false,
    canGoForward: true
  },
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case NEXT_PAGE:
      return { ...state };
    case PREVIOUS_PAGE:
      return { ...state };
    case SET_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarOpened: action.payload.state };
    case FILTER_STATE:
      return { ...state, filterState: action.payload.state };
    case FILTER_SEARCH:
      return { ...state, filterSearch: action.payload.search };
    case SET_USER_ID:
      return { ...state, password: action.payload.password, user_id: action.payload.userId, partner_id: action.payload.partnerId };
    case SET_USER:
      return { ...state, loading: false, user: action.payload.user };
    case SET_INVOICES:
      return { ...state, invoices: action.payload.invoices };
    case FETCH_INVOICES:
      return { ...state };
    case PRINT_INVOICE:
      return { ...state };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        // loading: false,
        payload: action.payload,
        error: "",
        loading: false
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        // loading: false,
        user: "",
        error: action.payload.message,
        loading: false
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: "success",
        forgotUserMessage: action.payload,
        error: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: "success",
        resetPasswordMessage: action.payload,
        resetPasswordCode: "",
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid, error: "" };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: "",
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, user: null, user_id: null, partner_id: null, loading: false, error: "" };
    default:
      return { ...state };
  }
};
