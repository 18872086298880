import { Link } from 'react-router-dom';
import whiteLogo from '../assets/images/logo-white.png';
import homeLogo from '../assets/images/icons/home.png';
import chevronRight from '../assets/images/icons/chevron-right.png';
import { connect } from 'react-redux';
import classNames from 'classnames';

function Sidebar({ user }) {
    return (
        <aside className={classNames(`w-64 h-full p-4 bg-primary-500 border-r border-primary-600 relative shadow-[0_0_15px_0_rgba(0,0,0,0.3)] -ml-64`)}>
            {
                user ?
                <div className="overflow-y-auto h-full">
                    <Link to="/">
                        <img src={whiteLogo} className="my-4 h-9 w-auto sm:flex-row flex-col" />
                    </Link>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" className="flex justify-between items-center p-2 text-base font-normal text-white rounded-lg hover:bg-primary-800">
                            <div className="">
                                <img src={homeLogo} className="inline-flex align-sub w-4 h-4 text-white transition duration-75" />
                                <span className="ml-3">Dashboard</span>
                            </div>
                            <img src={chevronRight} className="w-2 h-3 text-white transition duration-75 float-right" />
                            </Link>
                        </li>
                    </ul>
                </div>
                : null
            }
        </aside>
    );
}

const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return { user };
};
const mapActionsToProps = {};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Sidebar);