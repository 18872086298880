import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { connect } from 'react-redux';
import { loginUser, initiatePayment } from './redux/actions';
import { lazy, useEffect, useState } from 'react';
import Loader from './components/Loader';
import { toast, ToastContainer } from 'react-toastify';
import io from "socket.io-client";
import { baseURL } from './config/api';
import classNames from 'classnames';

const env = process.env.NODE_ENV;

const socket = io(baseURL, {
	path: `/server/socket.io`
});

const HomeView = lazy(() =>
  import(/* webpackChunkName: "home-view" */ './pages/Home')
);

const LoginView = lazy(() =>
  import(/* webpackChunkName: "login-view" */ './pages/Login')
);

const ForgotPasswordView = lazy(() =>
  import(/* webpackChunkName: "forgot-password-view" */ './pages/ForgotPassword')
);

const ProtectedRoute = ({ user }) => {
  const location = useLocation();
  return (
    user ? (
      <Outlet />
    ) : (
      <Navigate
        to={`/login`}
        state={{
          from: location
        }}
      />
    )
  );
}

const AuthRoute = ({ user }) => {
  const location = useLocation();
  if(location.state) {
    const { pathname } = location.state.from;
    localStorage.setItem('redirectTo', pathname);
  }
  return (
    !user ? (
      <Outlet />
    ) : (
      <Navigate
        to={`/`}
        state={{
          from: location
        }}
      />
    )
  );
}

function App({ initiatePayment, error, message, loading, sidebarOpened, user }) {
  
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected (true);
    });

    socket.on('invoicePaid', (data) => {
      const { metadata, amount, description } = data;
      console.log('Invoice Paid', data);
      const { invoiceId, invoiceNumber } = metadata;
      if(invoiceId && invoiceNumber) {
        initiatePayment(parseInt(invoiceId, 10), invoiceNumber, description, amount);
      }
    })

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    if(isConnected) {
      console.log('Connected to socket server.');
    } else {
      console.log('Disconnected from socket server.');
    }
  }, [ isConnected ]);

  useEffect( () => {
    if(error && error !== "") {
        toast.error(error);
    }
  }, [error]);

  useEffect( () => {
    if(message && message !== "") {
        toast.success(message);
    }
  }, [message]);

  if(loading) {
    return (
        <Loader />
    );
  }

  return (
    <div className={classNames({ 'flex h-full transition-all': true, 'translate-x-64': sidebarOpened })}>
        <Sidebar />
        <div className="w-full bg-slate-100 h-full overflow-auto">
          {
              user ?
              <Navbar />
              : null
          }
          <div className="p-6 md:p-10">
              <ToastContainer />
              <Routes>


                <Route element={ <AuthRoute user={user} /> }>
                    <Route path="/login" element={<LoginView />} />
                    <Route path="/forgot-password" element={<ForgotPasswordView />} />
                </Route>

                <Route element={ <ProtectedRoute user={user} /> }>
                    <Route path="/" element={<HomeView />} />
                </Route>


              </Routes>
          </div>
        </div>
    </div>
  );
}


const mapStateToProps = ({ payment, authUser }) => {
   const { loading, user_id, token, user, sidebarOpened } = authUser;
   const { error, message } = payment;
   return { loading, user_id, token, user, sidebarOpened, error, message };
 };
 const mapActionsToProps = {initiatePayment, loginUser};
 
 export default connect(
   mapStateToProps,
   mapActionsToProps
 )(App);
 
